@font-face {
    font-family: 'Raleway Bold';
    src: url("../fonts/Raleway/Raleway-Bold.ttf");
}

@font-face {
    font-family: 'Raleway SemiBold';
    src: url("../fonts/Raleway/Raleway-SemiBold.ttf");
}

@font-face {
    font-family: 'Raleway Regular';
    src: url("../fonts/Raleway/Raleway-Regular.ttf");
}

@font-face {
    font-family: 'Raleway Medium';
    src: url("../fonts/Raleway/Raleway-Medium.ttf");
}

@font-face {
    font-family: 'Zilla Slab Regular';
    src: url("../fonts/ZillaSlab/ZillaSlab-Regular.ttf");
}

@font-face {
    font-family: 'Zilla Slab Medium';
    src: url("../fonts/ZillaSlab/ZillaSlab-Medium.ttf");
}

@font-face {
    font-family: 'Brandon Bold';
    src: url("../fonts/BrandonText/BrandonText-Bold.otf");
}

html, body {
    font-family: "Raleway Medium", sans-serif;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    background-color: #FFFFFF !important;
}

.container {
    margin: 25px auto;
    max-width: 1280px;
    padding: 0.5rem 0;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767px) {
    .container {
        padding: 0 30px;
        max-width: 100%;
    }

    html, body {
        background-color: white !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        padding: 0 30px;
        max-width: 720px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) and (max-width: 1279px) {
    .container {
        padding: 0 30px;
        max-width: 960px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1280px) {
    .container {
        padding: 0 30px;
        max-width: 1140px;
    }
}

.styled-link {
    color: red;
    font-family: "Raleway Bold", sans-serif;
}

@media only screen and (min-width: 767px) {
    .box-shadow {
        background-color: white !important;
        box-shadow: 0 3px 6px 0px rgba(0,0,0,0.16);
    }
}

.MuiDialog-root {
    z-index: 999999999 !important;
}
